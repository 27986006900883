import OrganisationStore from './OrganisationStore'
import UserStore from './UserStore'

const organisationStore = new OrganisationStore()
const userStore = new UserStore()

const stores = {
  organisationStore,
  userStore
}

export default stores
