import { Box, Heading, Image } from 'grommet'
import React from 'react'
import logo from '../assets/kband_logo_long.png'

function AuthLayout ({ children }) {
  return (
    <Box fill>
      <Box direction="row" flex overflow={{ horizontal: 'hidden' }}>
        <Box flex pad={'large'}>
          <Box
            margin={{bottom: 'xlarge'}}
            align={'start'}>
            <Image
              style={{height: 60}}
              fit={'cover'}
              src={logo}
            />
          </Box>
          <Box
            flex
            pad={{'horizontal': 'xlarge'}}
            align={'start'}>
            <Heading
              color={'brand'}
              level={1}>
              Bienvenue
            </Heading>
            {children}
          </Box>
        </Box>
        <Box
          flex
          background={'url(/assets/illustration/login_bg.jpg)'}
        />
      </Box>
    </Box>
  )
}

export default AuthLayout
