import { inject, observer } from 'mobx-react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import BusinessRegistration from '../screens/auth/BusinessRegistration'
import AuthRoutes from './AuthRoutes'

function privateRoute ({ children, userStore, ...rest }) {
  let auth = userStore.uid
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

const PrivateRoute = inject('userStore')(observer(privateRoute))

function onlyPublicRoute ({ children, userStore, ...rest }) {
  let auth = userStore.uid
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

const OnlyPublicRoute = inject('userStore')(observer(onlyPublicRoute))

function Router () {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/business-registration'} component={BusinessRegistration}/>
        <OnlyPublicRoute path="/auth">
          <AuthRoutes/>
        </OnlyPublicRoute>
        <PrivateRoute path="/protected">
          ProtectedPage
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  )
}

export default inject('userStore')(observer(Router))
