import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import { initFirestorter } from 'firestorter'

firebase.initializeApp({
  apiKey: 'AIzaSyCGPIfEIta9xCiZmHEmhQTWMwDTA0gn0Cc',
  authDomain: 'kband-90ade.firebaseapp.com',
  databaseURL: 'https://kband-90ade.firebaseio.com',
  projectId: 'kband-90ade',
  storageBucket: 'kband-90ade.appspot.com',
  messagingSenderId: '1049355663020',
  appId: '1:1049355663020:web:d45cbc9275d30e3e58d109',
  measurementId: 'G-D2B57ZD6KF'
})

initFirestorter({ firebase })

const fn = firebase.app().functions('us-east1')
// ToDo uncomment for prod is fo local
// const fn = firebase.app().functions()

// fn.useEmulator('192.168.1.128', 5001)


const storage = firebase.storage

export {
  fn,
  storage,
  firebase as default
}
