import { Box, Image, ResponsiveContext } from 'grommet'
import { observer } from 'mobx-react'
import React from 'react'
import logo from '../../assets/kband_logo_long.png'
import BusinessRegistrationForm from '../../components/forms/BusinessRegistrationForm'

function BusinessRegistration () {
  const size = React.useContext(ResponsiveContext)

  return (
    <Box fill>
      <Box direction="row" flex overflow={{ horizontal: 'hidden' }}>
        <Box flex pad={'large'}>
          <Box
            margin={{ bottom: 'xlarge' }}
            align={'start'}>
            <Image
              style={{ height: 60, width: 160 }}
              fit={'cover'}
              src={logo}
            />
          </Box>
          <Box
            overflow={{ horizontal: 'scroll' }}
            flex
            pad={{ 'horizontal': 'xlarge' }}
            align={'start'}>
            <Box fill>
              <BusinessRegistrationForm/>
            </Box>
          </Box>
        </Box>
        {size !== 'small' && (
          <Box
            flex
            background={'url(/assets/illustration/login_bg.jpg)'}
          />
        )}
      </Box>
    </Box>
  )
}

export default observer(BusinessRegistration)
