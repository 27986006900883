import firebase from '../firebase'
import { Document } from 'firestorter'
import { action, makeObservable, observable } from 'mobx'

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class UserStore extends Document {
  currentUser = null

  constructor () {
    super()
    makeObservable(this, {
      currentUser: observable,
      signInWithPhone: action,
      signInWithEmail: action,
      signUpWithEmail: action,
      logout: action
    })
    this.currentUser = firebase.auth().currentUser
    this.observeAuth()
  }

  observeAuth () {
    firebase.auth().onAuthStateChanged((user) => this.onAuthStateChanged(user))
  }

  onAuthStateChanged (user) {
    console.log('logged ?', !!user)
    if (!user) {
      this.currentUser = null
      this.path = 'user/00'
    } else {
      this.currentUser = user
      console.log('user logged', user)
      this.path = `users/${user.uid}`
    }
  }

  async signInWithPhone (phone) {
    return firebase.auth().signInWithPhoneNumber(phone)
  }

  async signInWithEmail ({
    email,
    password
  }) {
    this.isSignedIn = true
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password)
    console.log('userCredential', userCredential)
  }

  async signUpWithEmail ({
    firstname: firstName,
    lastname: lastName,
    email,
    password
  }) {
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password)
    await timeout(1500)
    await this.update({
      firstName,
      lastName
    })
    console.log('userCredential', userCredential)
  }

  async logout () {
    await firebase.auth().signOut()
    this.currentUser = null
    return null
  }
}


export default UserStore
