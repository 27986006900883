const theme = {
  global: {
    colors: {
      brand: '#4357fc'
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px'
    }
  }
}

export default theme
