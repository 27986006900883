import { Box, Heading } from 'grommet'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LoginForm from '../../components/forms/LoginForm'

function SignIn ({ userStore }) {
  return (
    <Box fill>
      <Heading level={3}>Connexion</Heading>
      <LoginForm/>
    </Box>
  )
}

export default inject('userStore')(observer(SignIn))
