import { Grommet } from 'grommet'
import { Provider } from 'mobx-react'
import React from 'react'
import Router from './routes'
import stores from './stores'
import theme from './themes'

function App () {
  return (
    <Grommet theme={theme} full>
      <Provider {...stores}>
        <Router/>
      </Provider>
    </Grommet>
  )
}

export default App
