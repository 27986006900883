import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AuthLayout from '../layouts/AuthLayout'
import Login from '../screens/auth/Login'
import SignIn from '../screens/auth/SignIn'

function AuthRoutes () {
  // let { path, url } = useRouteMatch()

  return (
    <AuthLayout>
      <Switch>
        <Route exact path={''} component={Login}/>
        <Route path="/signin" component={SignIn}/>
      </Switch>
    </AuthLayout>
  )
}

export default AuthRoutes
