import {
  Box,
  Button,
  CheckBox,
  FileInput,
  Form,
  FormField,
  Heading,
  Paragraph,
  Select,
  Spinner,
  Text,
  TextInput
} from 'grommet'
import { inject, observer } from 'mobx-react'
import React, { useState } from 'react'
import ErrorMessage from './ErrorMessage'

function HomeStep ({ nextStep }) {
  return (
    <Box>
      <Heading
        color={'brand'}
        level={1}>
        Welcome
      </Heading>
      <Heading
        margin={{ vertical: 'small' }}
        level={2}>
        SXM best shopping week
      </Heading>
      <Heading
        margin={{ vertical: 'small' }}
        level={3}>
        Business Registration form
      </Heading>
      <Paragraph>
        Please fill in all the required fields in order to complete your registration form.
      </Paragraph>
      <Button
        size={'large'}
        margin={{ vertical: 'large' }}
        primary
        label={'Let\'s go'}
        onClick={() => nextStep()}/>
    </Box>
  )
}

function CompanyInfo ({ nextStep, prevStep, values }) {
  const [value, setValue] = useState(values)

  return (
    <Form
      value={value}
      onChange={nextValue => setValue(nextValue)}
      onSubmit={({ value }) => {
        console.log('values', value)
        nextStep(value)
      }}
    >
      <Heading level={4}>Company information</Heading>

      <FormField
        required
        name="businessName" htmlFor="businessName" label="Business name">
        <TextInput type="text" id="businessName" name="businessName"/>
      </FormField>
      <FormField
        required
        name="tradingName" htmlFor="tradingName" label="Trading name">
        <TextInput type="text" id="tradingName" name="tradingName"/>
      </FormField>
      <FormField
        required
        name="entityType" htmlFor="entityType" label="Entity type">
        <Select
          id="entityType"
          name="entityType"
          options={['Sole Proprietor', 'N.V.', 'B.V.']}
        />
      </FormField>
      <FormField
        required
        name="businessSector" htmlFor="businessSector" label="Business sector">
        <Select
          id="businessSector"
          name="businessSector"
          options={['Hotel', 'Retail', 'Service & Activity', 'Bar & Restaurant']}
        />
      </FormField>
      <FormField
        required
        name="businessActivity" htmlFor="businessActivity" label="Business activity">
        <TextInput type="text" id="businessActivity" name="businessActivity"/>
      </FormField>
      <FormField
        required
        name="businessAddress" htmlFor="businessAddress" label="Business Address">
        <TextInput type="text" id="businessAddress" name="businessAddress"/>
      </FormField>
      <FormField
        required
        name="businessTel" htmlFor="businessTel" label="Business Telephone Number">
        <TextInput type="tel" id="businessTel" name="businessTel"/>
      </FormField>
      <FormField
        required
        name="businessEmail" htmlFor="businessEmail" label="Business Email">
        <TextInput type="email" id="businessEmail" name="businessEmail"/>
      </FormField>
      <FormField
        required
        name="chamberNumber" htmlFor="chamberNumber" label="Chamber Number">
        <TextInput type="text" id="chamberNumber" name="chamberNumber"/>
      </FormField>
      <FormField
        required
        name="crib" htmlFor="crib" label="Crib Number / Tax ID Number">
        <TextInput type="text" id="crib" name="crib"/>
      </FormField>

      <Box
        margin={{ vertical: 'large' }}
        justify={'between'}
        direction="row" gap="medium">
        <Button
          onClick={() => prevStep()}
          secondary label="Prev"/>
        <Button type="submit" primary label="Next"/>
      </Box>
    </Form>
  )
}

function BusinessBankInfo ({ nextStep, prevStep, values }) {
  const [value, setValue] = useState(values)

  return (
    <Form
      value={value}
      onChange={nextValue => setValue(nextValue)}
      onSubmit={({ value }) => {
        console.log('values', value)
        nextStep(value)
      }}
    >
      <Heading
        margin={{ top: 'large' }}
        level={4}>Business banking information</Heading>

      <FormField
        required
        name="bankName" htmlFor="bankName" label="Bank Name">
        <TextInput type="text" id="bankName" name="bankName"/>
      </FormField>
      <FormField
        required
        name="accountName" htmlFor="accountName" label="Name on Account">
        <TextInput type="text" id="accountName" name="accountName"/>
      </FormField>
      <FormField
        required
        name="bankAccount" htmlFor="bankAccount" label="Bank Account Number">
        <TextInput type="text" id="bankAccount" name="bankAccount"/>
      </FormField>
      <FormField
        name="accountAddress" htmlFor="accountAddress" label="Address"
        info={'If address is same as mentioned above, you may skip this field.'}>
        <TextInput type="text" id="accountAddress" name="accountAddress"/>
      </FormField>

      <Box
        margin={{ vertical: 'large' }}
        justify={'between'}
        direction="row" gap="medium">
        <Button
          onClick={() => prevStep()}
          secondary label="Prev"/>
        <Button type="submit" primary label="Next"/>
      </Box>
    </Form>
  )
}

function BusinessContactInfo ({ prevStep, submitForm, values }) {
  const [value, setValue] = useState(values)

  return (
    <Form
      value={value}
      onChange={nextValue => setValue(nextValue)}
      onReset={() => setValue({})}
      onSubmit={({ value }) => {
        console.log('values', value)
        submitForm(value)
      }}
    >
      <Heading
        margin={{ top: 'large' }}
        level={4}>Business contact information</Heading>

      <FormField
        required
        name="employeeRank" htmlFor="employeeRank" label="Employee Rank">
        <Select
          id="employeeRank"
          name="employeeRank"
          options={['Executive', 'Manager', 'Staff']}
        />
      </FormField>
      <FormField
        required
        name="firstName" htmlFor="firstName" label="Firstname">
        <TextInput type="text" id="firstName" name="firstName"/>
      </FormField>
      <FormField
        required
        name="lastName" htmlFor="lastName" label="Lastname">
        <TextInput type="text" id="lastName" name="lastName"/>
      </FormField>
      <FormField
        required
        name="tel" htmlFor="tel" label="Telephone Number">
        <TextInput type="tel" id="tel" name="tel"/>
      </FormField>
      <FormField
        name="tel2" htmlFor="tel2" label="Secondary Telephone Number">
        <TextInput type="tel" id="tel2" name="tel2"/>
      </FormField>
      <FormField
        required
        name="email" htmlFor="email" label="Email">
        <TextInput type="email" id="email" name="email"/>
      </FormField>
      <FormField
        required
        name="idImageFile" htmlFor="idImageFile" label="ID / Passport">
        <FileInput
          accept="image/png, image/jpeg"
          name="idImageFile"
          id={'idImageFile'}
          // onChange={event => {
          //   const fileList = event.target.files;
          //   for (let i = 0; i < fileList.length; i += 1) {
          //     const file = fileList[i];
          //   }
          // }}
        />
      </FormField>

      <FormField
        margin={{ top: 'large' }}
        required
        name="tc" htmlFor="tc">
        <CheckBox
          id="tc"
          name="tc"
          label={<a target="_blank"
                    href="https://firebasestorage.googleapis.com/v0/b/kband-90ade.appspot.com/o/app%2FSXM%20shopping%20week%2FSXM%20Best%20Weekend%20Vendor%20Form.pdf?alt=media&token=05a18e48-3057-4911-b433-84361eaaa015">I
            read and agree to
            terms and conditions upon completing your registration form.</a>}
        />
      </FormField>


      <Box
        margin={{ vertical: 'large' }}
        justify={'between'}
        direction="row" gap="medium">
        <Button
          onClick={() => prevStep()}
          secondary label="Prev"/>
        <Button type="submit" primary label="Submit"/>
      </Box>
    </Form>
  )
}

function BusinessRegistrationForm ({ organisationStore }) {
  const [values, setValues] = useState({})
  const [step, setStep] = useState(0)

  const [onSubmit, setOnSubmit] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const [success, setSuccess] = useState(false)

  const submitForm = async (data) => {
    setErrorMessage()
    setOnSubmit(true)
    try {
      await organisationStore.registerOrganisation(data)
      setSuccess(true)
    } catch (e) {
      console.error(e.message)
      setErrorMessage(e.message)
    }
    setOnSubmit(false)
  }

  let StepComponent

  switch (step) {
    case 1:
      StepComponent = CompanyInfo
      break
    case 2:
      StepComponent = BusinessBankInfo
      break
    case 3:
      StepComponent = BusinessContactInfo
      break
    default:
      StepComponent = HomeStep
      break
  }

  if (success) {
    return (
      <Box>
        <Heading
          color={'status-ok'}
          level={1}>
          Thanks
        </Heading>
        <Heading
          margin={{ vertical: 'small' }}
          level={2}>
          You have completed your registration
        </Heading>
      </Box>
    )
  }

  return (
    <Box>
      {errorMessage && (
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
      )}
      {!onSubmit
        ? (
          <StepComponent
            values={values}
            submitForm={(v) => {
              setValues({
                ...values,
                ...v
              })
              submitForm(v)
            }}
            nextStep={(v) => {
              if (step < 3) {
                if (v) {
                  setValues({
                    ...values,
                    ...v
                  })
                }
                setStep(step + 1)
              }
            }}
            prevStep={() => {
              if (step > 0) {
                setStep(step - 1)
              }
            }}/>
        ) : (
          <Box
            direction={'column'}
            justify={'center'}
            align={'center'}
            fill>
            <Spinner
              size={'large'}/>
            <Text margin={{ vertical: 'medium' }}>Please wait...</Text>
          </Box>
        )}
    </Box>
  )
}

export default inject('organisationStore')(observer(BusinessRegistrationForm))
