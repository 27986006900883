import { Document } from 'firestorter'
import { action, makeObservable } from 'mobx'
import { fn } from '../firebase'
import StorageService from '../utils/StorageService'

class OrganisationStore extends Document {
  constructor () {
    super()
    makeObservable(this, {
      registerOrganisation: action
    })
  }

  async registerOrganisation ({ idImageFile, ...data }) {
    const userIdUri = await StorageService.uploadFile({
      ref: 'user-id',
      image: idImageFile[0],
      onUpload: ({
        transferred,
        total
      }) => {
        console.log('Math.round((transferred * 100) / total)', Math.round(transferred / total) * 100)
      }
    })
    const httpsCallable = fn.httpsCallable('organisation-addOrganisation')
    try {
      await httpsCallable({
        ...data,
        userIdUri
      })
    } catch (e) {
      console.log('registerOrganisation', e)
      throw e
    }
  }
}

export default OrganisationStore
