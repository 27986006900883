import { Box, Text } from 'grommet'
import React from 'react'

function ErrorMessage ({children}) {
  return (
    <Box
      pad={'small'}
      background={'status-error'}
      round={'medium'}>
      <Text>{children}</Text>
    </Box>
  )
}

export default ErrorMessage
