import { v4 as uuid } from 'uuid'
import { storage } from '../firebase'

async function uploadFile ({ ref, image, onUpload = () => {} }) {
  const { name } = image
  const ext = name.split('.').pop() // Extract image extension
  const filename = `${uuid()}.${ext}` // Generate unique name

  return new Promise((resolve, reject) => {
    storage()
      .ref(`${ref}/${filename}`)
      .put(image)
      .on(
        storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          onUpload({
            transferred: snapshot.bytesTransferred,
            total: snapshot.totalBytes
          })
        },
        error => {
          // unsubscribe()
          // alert('Sorry, Try again.')
          console.log('error', error)
          reject(error)
        },
        () => {
          storage()
            .ref(`${ref}/${filename}`)
            .getDownloadURL()
            .then(url => {
              resolve(url)
            })
        }
      )
  })
}

const fct = {
  uploadFile
}

export default fct
