import { Box, Button, Form, FormField, TextInput } from 'grommet'
import React, { useState } from 'react'

function LoginForm ({isSignin = false}) {
  const [value, setValue] = useState()

  return (
    <Form
      value={value}
      onChange={nextValue => setValue(nextValue)}
      onReset={() => setValue({})}
      onSubmit={({ value }) => {
        console.log('values', value)
      }}
    >
      <FormField name="email" htmlFor="email" label="Email">
        <TextInput type="email" id="email" name="email"/>
      </FormField>
      <FormField name="password" htmlFor="password" label="Mot de passe">
        <TextInput id="password" type="password" name="password"/>
      </FormField>
      <Box direction="column" gap="medium">
        <Button type="submit" primary label="Connexion"/>
        <Button label="Je n'ai pas encore de compte, m'inscrire"/>
      </Box>
    </Form>
  )
}

export default LoginForm
